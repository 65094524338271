import { createContext, ReactNode, ReactElement, useContext } from 'react';

export interface HistoryItem {
  url: string;
  as: string;
}

export interface GlobalState {
  history: HistoryItem[];
  isMobileFromSSR?: boolean;
}

const defaultState = {
  history: [],
};

const GlobalContext = createContext<GlobalState>(defaultState);

type Props = {
  children: ReactNode;
  value: GlobalState;
};

export const GlobalContextProvider = ({ children, value }: Props): ReactElement => {
  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export const useGlobalContext = (): GlobalState => useContext(GlobalContext);
