import snippet from '@segment/snippet';

const renderSnippet = (): string => {
  const method = process.env.NODE_ENV !== 'production' ? 'max' : 'min';
  return snippet[method]({ apiKey: process.env.SEGMENT_WRITE_KEY });
};

const analyticsScript = renderSnippet();

export default analyticsScript;
