/* eslint-disable react/no-danger */
import { ReactElement } from 'react';
import Head from 'next/head';

import analyticsScript from './analytics-script';
import uploadcareScript from './uploadcare-script';

const HeadComponent = (): ReactElement => (
  <Head>
    {/* Required meta tags always come first */}
    <meta charSet="utf-8" />
    <meta httpEquiv="x-ua-compatible" content="ie=edge" />
    {/* Favicon */}
    <link rel="icon" type="image/png" href="/img/favicon.png" />
    <link
      href="https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap"
      rel="stylesheet"
    />
    {process.env.NODE_ENV === 'production' && (
      <script dangerouslySetInnerHTML={{ __html: analyticsScript }} />
    )}
    <script dangerouslySetInnerHTML={{ __html: uploadcareScript }} />
  </Head>
);

export default HeadComponent;
