import { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { noop } from 'lodash';

export const CloseButton = ({ closeToast = noop }: { closeToast?: () => void }): ReactElement => (
  <FontAwesomeIcon
    icon="times"
    className="my-2 fs--2"
    style={{ opacity: 0.5 }}
    onClick={closeToast}
  />
);
