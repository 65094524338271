import * as SentryNode from '@sentry/node';
import * as SentryBrowser from '@sentry/browser';

const sentryOptions = {
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.SENTRY_DSN,
  release: process.env.VERCEL_GIT_COMMIT_SHA,
};

const initSentry = (): void =>
  typeof window === 'undefined'
    ? SentryNode.init(sentryOptions)
    : SentryBrowser.init({ ...sentryOptions, autoSessionTracking: false });

export default initSentry;
