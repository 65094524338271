import 'intersection-observer';
import { ReactElement } from 'react';
import App, { AppInitialProps, AppContext } from 'next/app';
import { NextPageContext, NextComponentType } from 'next';
import { config, library } from '@fortawesome/fontawesome-svg-core';
import { ToastContainer, Zoom } from 'react-toastify';
import { Router } from 'next/router';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import last from 'lodash/last';
import { ApolloCache } from '@apollo/client';
import MobileDetect from 'mobile-detect';
import smoothscroll from 'smoothscroll-polyfill';

// font-awesome
import '@fortawesome/fontawesome-svg-core/styles.css';
// react-toastify
import 'react-toastify/dist/ReactToastify.css';
// react-datepicker css
import 'react-datepicker/dist/react-datepicker.css';
// react-notion-x
import 'react-notion-x/src/styles.css';
import 'rc-dropdown/assets/index.css';
// falcon
import '../scss/theme.scss';

import initSentry from '../lib/sentry';
import InvestorFrontendHead from '../components/head';
import { GlobalContextProvider, GlobalState } from '../components/context';
import { CloseButton } from '../components/lib/Toast';
// import InboxModalContextProvider from '../components/lib/inbox-modal/context/provider';

library.add(fas);
library.add(far);
library.add(fab);
config.autoAddCss = false;

interface InvestorFrontendAppProps extends AppInitialProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: NextComponentType<NextPageContext, any, GlobalState>;
  router: Router;
  isMobileFromSSR?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client?: ApolloCache<Record<string, any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InvestorFrontendAppInitialProps = { pageProps: any; isMobileFromSSR?: boolean };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class InvestorFrontendApp extends App<InvestorFrontendAppProps, any, GlobalState> {
  constructor(props: InvestorFrontendAppProps) {
    super(props);
    initSentry();
    this.state = {
      history: [],
    };
  }

  addHistoryRecord = (url: string): void => {
    const previousItem = last(this.state.history);
    if (!previousItem || previousItem.as !== url) {
      this.setState((prevState) => ({
        history: [...prevState.history, { as: url, url }],
      }));
    }
  };

  onRouteChangeComplete = (url: string): void => {
    this.addHistoryRecord(url);
    // window.scroll({ top: 0 });
  };

  componentDidMount(): void {
    const { asPath } = this.props.router;
    this.addHistoryRecord(asPath);
    Router.events.on('routeChangeComplete', this.onRouteChangeComplete);
    smoothscroll.polyfill();
  }

  componentWillUnmount(): void {
    Router.events.off('routeChangeComplete', this.onRouteChangeComplete);
  }

  static async getInitialProps({
    Component,
    ctx,
  }: AppContext): Promise<InvestorFrontendAppInitialProps> {
    const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
    if (ctx.req?.headers) {
      const md = new MobileDetect(ctx.req.headers['user-agent'] || '');
      const isMobileFromSSR = !!md.phone();
      return { pageProps, isMobileFromSSR };
    }
    return { pageProps };
  }

  render(): ReactElement {
    const { Component, pageProps, isMobileFromSSR, err } = this.props;
    if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
      return (
        <article>
          <h1>We&rsquo;ll be back soon!</h1>
          <div>
            <p>
              Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment.
              We&rsquo;ll be back online shortly!
            </p>
            <p>&mdash; The Zenvest Team</p>
          </div>
          <style jsx>
            {`
              body {
                text-align: center;
                padding: 150px;
              }
              h1 {
                font-size: 50px;
                margin-bottom: 20px;
              }
              body {
                font: 20px Helvetica, sans-serif;
                color: #333;
              }
              article {
                display: block;
                text-align: left;
                width: 650px;
                margin: 0 auto;
                padding: 60px;
              }
              a {
                color: #dc8100;
                text-decoration: none;
              }
              a:hover {
                color: #333;
                text-decoration: none;
              }
            `}
          </style>
        </article>
      );
    }
    return (
      <>
        <InvestorFrontendHead />
        <GlobalContextProvider value={{ ...this.state, isMobileFromSSR }}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...pageProps} err={err} />
        </GlobalContextProvider>
        <ToastContainer
          autoClose={3000}
          transition={Zoom}
          closeButton={<CloseButton />}
          position="bottom-left"
        />
      </>
    );
  }
}

export default InvestorFrontendApp;
